import React from "react"

import { graphql } from "gatsby"

import Seo from "../components/Seo"

import Layout from "../components/Layout"
import SafeAreaWrapper from "../components/SafeAreaWrapper"
import Grid from "../components/Grid"

import Hero from "../components/Hero"

import SmallStage from "../components/SmallStage"
import Title from "../components/Title"

// import { ExperienceSolutionBanner } from "../components/Banner"

import isAbsoluteUrl from "is-absolute-url"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import ImprintCard from "../components/ImprintCard"

const components = {
	ImprintCard,

	h1: ({ children, ...props }) => (
		<h2 className="display3 mt-40 mb-30" {...props}>
			{children}
		</h2>
	),
	h2: ({ children, ...props }) => (
		<h3 className="display3 mt-40 mb-30" {...props}>
			{children}
		</h3>
	),
	h3: ({ children, ...props }) => (
		<h4 className="display3 mt-40 mb-30" {...props}>
			{children}
		</h4>
	),
	h4: ({ children, ...props }) => (
		<h5 className="display3 mt-40 mb-30" {...props}>
			{children}
		</h5>
	),
	h5: ({ children, ...props }) => (
		<h6 className="display3 mt-40 mb-30" {...props}>
			{children}
		</h6>
	),
	h6: ({ children, ...props }) => (
		<h6 className="display3 mt-40 mb-30" {...props}>
			{children}
		</h6>
	),
	img: ({ src, alt = null, title, props }) => {
		title = title ? title.replace(/\{.*\}/g, "") : null
		return (
			<figure className="md:my-50 lg:my-60">
				<img
					className="rounded-small"
					title={title}
					alt={alt || title}
					src={isAbsoluteUrl(src) ? src : `https://hanseaticsoft.imgix.net/${src}`}
					{...props}
				/>
				{title && (
					<figcaption className="psmall text-tealblue text-md mt-10">{title}</figcaption>
				)}
			</figure>
		)
	},
	a: ({ children, ...props }) => (
		<a
			className="text-tealblue underline underline-offset-2 decoration-2"
			target={isAbsoluteUrl(props.href) ? "_blank" : ""}
			{...props}>
			{children}
		</a>
	),
	p: props => <p className="bodyp my-6" {...props} />,
	blockquote: props => (
		<blockquote className="bodyp my-20 p-18 bg-porcelain text-sm rounded-medium" {...props} />
	),
	cite: props => <cite className="text-sirocco" {...props} />,
	small: props => <small className="text-sirocco" {...props} />,
	ul: props => <ul className="list-disc my-20 ml-20" {...props} />,
	li: props => <li className="bodyp text-nevada leading-28 text-base my-8" {...props} />,
}

const StaticPage = ({ children, data: { mdx } }) => {
	return (
		<Layout translucent={mdx.frontmatter.translucent || false}>
			<Seo title={mdx.frontmatter.title} description={mdx.frontmatter.description} />

			{mdx.frontmatter?.small ? (
				<SmallStage className="pt-[60px] md:pt-[80px]">
					<Title
						title={mdx.frontmatter.title}
						small={mdx.frontmatter.small}
						tint={mdx.frontmatter.tint}
						translucent={mdx.frontmatter.translucent}
					/>
				</SmallStage>
			) : (
				<Hero
					title={mdx.frontmatter.title}
					subtitle={mdx.frontmatter.subtitle}
					backgroundImage={mdx.frontmatter.backgroundImage}
					small={mdx.frontmatter.small}
					tint={mdx.frontmatter.tint}
					translucent={mdx.frontmatter.translucent}
				/>
			)}

			<SafeAreaWrapper>
				<div className="py-spacer-xl">
					<Grid>
						<div className="col-start-1 col-end-12 md:col-start-2 md:col-end-12 lg:col-start-3 lg:col-end-11">
							<MDXProvider components={components}>
								<MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
							</MDXProvider>
						</div>
					</Grid>
				</div>
			</SafeAreaWrapper>
		</Layout>
	)
}

/* <ExperienceSolutionBanner className="mt-spacer-xl" /> */

export default StaticPage

export const pageQuery = graphql`
	query ($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			frontmatter {
				title
				subtitle
				small
				tint
				translucent
				backgroundImage {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
					}
				}
			}
		}
	}
`
