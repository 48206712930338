import * as React from "react"

import Grid from "./Grid"
import FullLogo from "./FullLogo"

const ImprintCard = () => (
	<Grid withContainer={false}>
		<div className="col-start-2 col-span-11 md:col-start-2 md:col-span-10  bg-[#F8F8F8] shadow-md px-[20px] py-[34px] md:px-[40px] md:py-[64px]">
			<div className="w-full">
				<FullLogo className="h-[50px] xl:h-[70px]" />
			</div>
			<div className="flex flex-col xl:flex-row">
				<div className="xl:basis-1/2 flex flex-col mt-20 md:mt-[50px]">
					<div>
						<strong>Hanseaticsoft GmbH</strong>
					</div>
					<div>Poßmoorweg 2</div>
					<div>D-22301 Hamburg</div>
					<div className="mt-12">
						<strong>CEO</strong>
					</div>
					<div>Alexander Buchmann</div>
				</div>

				<div className="xl:basis-1/2 flex flex-col mt-20 md:mt-[50px]">
					<div>
						<strong>Commercial Register</strong>
					</div>
					<div>Amtsgericht Hamburg HRB 116313</div>
					<div>
						<strong>VAT-ID</strong>&nbsp;DE274981583
					</div>
					<div className="mt-12">
						<strong>Phone</strong>&nbsp;+49 (0)40 349 700 10 210
					</div>
					<div>
						<strong>Mail</strong>&nbsp;info@hanseaticsoft.com
					</div>
				</div>
			</div>
		</div>
	</Grid>
)

export default ImprintCard
