import * as React from "react"

import Container from "./Container"

const Title = ({ title, subtitle, small = false, tint = true }) => (
	<Container>
		<div
			// max-w-[600px] md:max-w-none
			className={`flex flex-col items-center justify-center mx-20 md:mx-30 xl:mx-50 ${
				small
					? "h-[207px] md:h-[254px] xl:h-[308px]"
					: "h-[400px] lg:h-[450px] xl:h-[500px]"
			}`}>
			<h1 className={`display1 text-center ${tint ? "text-white" : "text-jetblack"}`}>
				{title}
			</h1>
			{subtitle && (
				<h2 className={`display1 text-center ${tint ? "text-[#CAE6FA]" : "text-jetblack"}`}>
					{subtitle}
				</h2>
			)}
		</div>
	</Container>
)

export default Title
