import React from "react"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

// import SafeAreaWrapper from "./SafeAreaWrapper"
import Container from "./Container"
// import Grid from "./Grid"

const Hero = ({
	title,
	subtitle,
	backgroundImage,
	legacyImagePlugin = false,
	tint = true,
	small = false,
	translucent,
}) => (
	<div className={`w-full relative overflow-hidden`}>
		<BgImage
			alt={title}
			Tag="div"
			className="bg-cover bg-center"
			image={getImage(backgroundImage)}>
			<div
				className={`${tint ? "bg-[#0066B2] bg-opacity-60" : ""} ${
					translucent ? "" : "mt-[60px] md:mt-[80px]"
				}`}>
				<Container>
					<div
						// max-w-[600px] md:max-w-none
						className={`flex flex-col items-center justify-center mx-20 md:mx-30 xl:mx-50 ${
							small
								? "h-[207px] md:h-[254px] xl:h-[308px]"
								: "h-[400px] lg:h-[450px] xl:h-[500px]"
						}`}>
						<h1
							className={`display1 text-center ${
								tint ? "text-white" : "text-jetblack"
							}`}>
							{title}
						</h1>
						{subtitle && (
							<h2
								className={`display1 text-center ${
									tint ? "text-[#CAE6FA]" : "text-jetblack"
								}`}>
								{subtitle}
							</h2>
						)}
					</div>
				</Container>
			</div>
		</BgImage>
	</div>
)

export default Hero
